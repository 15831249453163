import 'dayjs/locale/de-at';
import dsTranslations from '@loveholidays/design-system/lib/translations/de-DE.json';
import dayjs from 'dayjs';

dayjs.locale('de-at');

import translations from './main.json';

export const namespaces = {
  '': translations,
  lvhds: dsTranslations,
};
